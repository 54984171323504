import React, { Component } from 'react';

import ReactDataGrid from 'react-data-grid';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import * as moment from 'moment';
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Menu from '@material-ui/core/Menu';
import {MenuAppBar} from './MenuAppBar';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PeopleIcon from '@material-ui/icons/People';
import 'bootstrap/dist/css/bootstrap.css';

import {
  Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button
} from 'reactstrap';



const columns = [{ key: 'id', name: 'ID' }, { key: 'title', name: 'Title' }];
const rows = [{ id: 1, title: 'Title 1' },  { id: 2, title: 'Title 2' }];
const rowGetter = rowNumber => rows[rowNumber];

/*const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const useStyles1 = makeStyles({
  table: {
    minWidth: 650,
  },
}); */

const Grid = () => {
  return <ReactDataGrid
    columns={columns}
    rowGetter={rowGetter}
    rowsCount={rows.length}
    minHeight={500} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));



class Homepage extends Component {

  constructor(props) {
    super(props);
    this.state = {profile: props.location.state.profile,
      stclasses: props.location.state.stclasses,
    selectedSchedule: '',
    dialogOpen: false,
    deleteClass: '',
    listClass: '',
    classOpen: false,
    classSchedule: '',
    studentlist: [],
    availableClasses: []
    };
  //  this.setMenu = this.setMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleClassClose = this.handleClassClose.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  //classes = useStyles1();

  componentWillMount() {
 
  }

  componentWillUpdate() {
  
  }
  async componentDidMount() {
    document.title='Super10';
    let schedules = await getAvailableClasses();
    this.setState({
      availableClasses: schedules
    });
  }
    
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

    handleOptionChange = changeEvent => {
      this.setState({
        selectedOption: changeEvent.target.value
      });
    };

    handleScheduleChange = e => {
      this.setState ({
        selectedSchedule: e.target.value
      });
      
    }

    getnextclasses() {

      var date = new Date();
    
      let schedules = [];
     
    
      let daysofweek=[1,2,4];
      date.setDate(date.getDate() - 7);
      for (let k=0; k<4; k++) {
        date.setDate(date.getDate() + 7);
        for (let j=0; j<daysofweek.length; j++) {
         
          var indate = this.getNextDayOfWeek(date,daysofweek[j], 2)
          var inday = moment(indate).format('DD-MM-YYYY');
          let dayOfWeek= indate.getDay();
          let time=this.getTime(dayOfWeek);
          schedules.push(inday +' '+ time);
      
      
        }
    
      }
    
      return schedules;
    
    
    }
    
    getNextDayOfWeek(date, dayOfWeek, weekNumber) {
      // Code to check that date and dayOfWeek are valid left as an exercise ;)
    
      var resultDate = new Date(date.getTime());
    
      resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    
      return resultDate;
    }
    
    getTime(day) {
      switch(day) {
        case 1: return '18:15';
        break;
        default:
        case 2: return '06:00';
        
      }
    }

    renderSchedules(){

     // let schedules = this.getnextclasses();

     // let schedules = await getAvailableClasses();
      
      return this.state.availableClasses.map((bg,i) => {
        return (
          <MenuItem value={bg.schedule}>{moment.utc(bg.schedule,moment.defaultFormat).format('DD-MMM dddd HH:mm')}</MenuItem>
        )
      })
    //  <MenuItem value={bg.idStClassSchedule}>{bg.schedule}</MenuItem>
      
    }
 
  handleSubmit(event) {
    
    

    let selectedDate = moment.utc(this.state.selectedSchedule,moment.defaultFormat).format('DD-MM-YYYY HH:mm');


    let obj = {"schedule": selectedDate
    };
    // console.log(obj);
    //   var buff = new bufferFrom(JSON.stringify(obj)).toString("base64");
    let jsonData = signupForClass(obj).then(
    externalData => {
    //     console.log('*******async done');
    //    console.log(externalData);
    if (externalData === 401) {
        this.setState({ errorMessage: 'Invalid Credentials'});
    } else {
        //    if (externalData.roles.includes('admin')) {
            this.setState({stclasses: externalData});
            let availableClasses = this.state.availableClasses;
            let objIndex = availableClasses.findIndex((obj => obj.schedule == this.state.selectedSchedule));
            
            let removedClasses = availableClasses.splice(objIndex,1);
 
            this.setState({availableClasses: availableClasses, selectedSchedule: ''}); 
            this.props.history.push({
                pathname: '/home'
            //    state: {profile: this.state.profile}
            })  
      //  } else {
        //    this.setState({ errorMessage: 'Not Authorized to perform Admin function'});
        }
    }
    // }
    );
    event.preventDefault();
  }

  updatePaymentStatus(idStClass, paymentStatus) {

    let updatedSchedules = this.state.stclasses;

    let objIndex = updatedSchedules.findIndex((obj => obj.idStClass == idStClass));

    updatedSchedules[objIndex].paymentStatus = paymentStatus;

    this.setState({stclasses : updatedSchedules});

    let obj = {"idStClass": idStClass, "paymentStatus": paymentStatus
    };

    let jsonData = updatePaymentInfo(obj).then(
      externalData => {
      //     console.log('*******async done');
      //    console.log(externalData);
      if (externalData === 400) {
          alert('Oops, something went wrong');
      } else {
      //  alert(`payment status for ${idStClass} is ${paymentStatus}`);
          }
      }
      // }
      );
    //  event.preventDefault();
  
  }

  handleDelete() {

  /*  let updatedSchedules = this.state.stclasses;

    let objIndex = updatedSchedules.findIndex((obj => obj.schedule == schedule));

    let removedSchedules = updatedSchedules.splice(objIndex,1);

    this.setState({stclasses : updatedSchedules});

    this.setState({dialogClose: true}); */

  //  alert('can be deleted now')

  }

  handleDialogOpen(e) {

   /*  let updatedSchedules = this.state.stclasses;
 
     let objIndex = updatedSchedules.findIndex((obj => obj.schedule == schedule));
 
     let removedSchedules = updatedSchedules.splice(objIndex,1);
 
     this.setState({stclasses : updatedSchedules}); */
 
     this.setState({dialogOpen: true});
 
 
   
   }

   async handleDialogClose(event) {

   

    this.setState({dialogOpen: false});

    if (event.target.name === 'confirm') {
      let updatedSchedules = this.state.stclasses;

      let objIndex = updatedSchedules.findIndex((obj => obj.idStClass == this.state.deleteClass.idStClass));

      let removedSchedules = updatedSchedules.splice(objIndex,1);

      this.setState({stclasses : updatedSchedules, deleteSchedule: ''});

      let obj = {"idStClass": this.state.deleteClass.idStClass};

      let jsonData = deleteClass(obj).then(
        externalData => {
        //     console.log('*******async done');
        //    console.log(externalData);
        if (externalData === 400) {
            alert('Oops, something went wrong');
        } else {
          let schedules = getAvailableClasses().then(
              externalData => {
                    this.setState({
                      availableClasses: externalData
                    });
                    alert(` ${this.state.deleteClass.idStClass} Enrollment is cancelled`);
              }
          
            )}
        }
        // }
        );
      }

      if (event.target.name === 'cancel') {
        this.setState({deleteClass: null});
      }


  
  }

  getEnrollments(classId) {


    let jsonData = getEnrollmentsById(classId).then(
      externalData => {
      //     console.log('*******async done');
      //    console.log(externalData);
      if (externalData === 400) {
          alert('Oops, something went wrong');
      } else {
        this.setState({studentlist: externalData});
          }
      }
      // }
      );
  }

  handleClassClose(event) {

    this.setState({classOpen: false});
  
  }



  updateProfile() {

  //  alert(JSON.stringify(this.state.stclasses));
    this.props.history.push({
      pathname: '/profile',
  //    state: {profile: this.state.profile}
      state: {profile: this.state.profile, stclasses: this.state.stclasses}
  })  
  }


  //  classes = useStyles();

  render() {

   

    
    return(

      

    <div>
    
    {/*<HeaderBar user={this.state.profile.name} menu='Manage your ST sessions' displayLogout='true'/>*/}
    <MenuAppBar updateProfile={this.updateProfile} name={this.state.profile.name} showMenu={this.state.profile.phonenumber==='1' ? 'true': 'false'} loggedIn='true'/>


    <Container>
    <form onSubmit={this.handleSubmit}>

    <div>
       
        <div>
        <div className="col text-center justify-content-center align-self-center">
          
          </div>
        </div>
        <div>
        <div className="col text-center justify-content-center align-self-center">

        <label> <h4>Your upcoming ST sessions</h4> </label>
        <TableContainer component={Paper}>
          <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Schedule</TableCell>
                <TableCell>Enrollment Status</TableCell>
                <TableCell>Payment Status</TableCell>
                {/*<TableCell>Cancel</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.stclasses.map(row => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    <>
                        {moment.utc(row.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}
                      <IconButton aria-label="delete" onClick={event => this.setState({deleteClass: row, dialogOpen: true})}>
                          <DeleteIcon /> </IconButton> 
                    </>
                  </TableCell>
                  <TableCell align="left">{row.enrollmentStatus}
                  <IconButton aria-label="list" onClick={event => {this.setState({listClass: row, classOpen: true}); this.getEnrollments(row.idStClassSchedule)}}>
                      <PeopleIcon /> </IconButton> 
                  </TableCell>
                  <TableCell align="left"> {row.paymentStatus}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleDialogClose}
        confirmState={this.showName}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{`Cancel booking for the class scheduled on ${moment.utc(this.state.deleteClass.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
               Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name='cancel'onClick={this.handleDialogClose} color="primary">
              No
            </Button>
            <Button name='confirm' onClick={this.handleDialogClose} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
     </Dialog>
     <Dialog
        open={this.state.classOpen}
        onClose={this.handleClassClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{`List of Students for the class ${moment.utc(this.state.listClass.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {/*{moment(this.state.listClass.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}*/}
                <TableContainer component={Paper}>
                  <Table  aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Enrollment Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.studentlist.map(row => (
                        <TableRow key={row.st_name}>
                          <TableCell align="left">
                           {row.st_name}
                          </TableCell>
                          <TableCell align="left">{row.stc_enrollmentStatus}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name='confirm' onClick={this.handleClassClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
     </Dialog>

    <br></br><br></br>
          <label>
                <h4> Register for next ST Session </h4>        </label>
                

                <Select
                    label="Book Your Class"
                    autoWidth
                    value={this.state.selectedSchedule}
                    onChange={this.handleScheduleChange}
                    MenuProps={this.MenuProps}
                  >
                    {this.renderSchedules()}
                      {/*<MenuItem key='16/03/2020 18:15' value='16/03/2020 18:15'> 16/03/2020 18:15</MenuItem>
                      <MenuItem key='17/03/2020 06:00' value='17/03/2020 06:00'> 17/03/2020 06:00</MenuItem>
                      <MenuItem key='19/03/2020 06:00' value='19/03/2020 06:00'>19/03/2020 06:00</MenuItem>
                      <MenuItem key='16/03/2020 18:15' value='16/03/2020 18:15'>16/03/2020 18:15</MenuItem>*/}
                     
                    </Select>

                
          </div>
        </div>
          
        <div className="col text-center justify-content-center align-self-center">
          <button className="btn btn-primary mt-2" type="submit" disabled={this.state.selectedSchedule === ''}>
            Submit
          </button>
        </div>
          </div>
      </form>
      </Container>

    </div>  
 
    )

  }
}

export default  (Homepage);

async function signupForClass(data) {
  //   console.log('inside login function');
   //  console.log(creds);
         const URL= process.env.REACT_APP_API_URL+'student/enroll';
   //  const URL = 'http://dev.rhapp.in/api/runner/login';
   //  const URL = 'http://localhost/api/runner/login';

 //  alert(JSON.stringify(data));
   const token = localStorage.getItem('token');

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
 
   try {
     const response = await axios.post(URL, data, config );
 //    console.log('👉 Returned data:', response);
   //  alert(JSON.stringify(response));
     return response.data;
   } catch (e) {
     console.log(`😱 Axios request failed: ${e}`);
   }
     
     /*
     const fetchResult = fetch(URL, {
         method: 'POST',
         body: '',
         headers: {
             Authorization: 'Bearer ' + creds
         },
         credentials: 'include'
 
     })
     const response = await fetchResult;
   //  console.log(response.status);
     if (response.status === 200) {
         const jsonData = await response.json();
     // console.log(jsonData.profile);
     // console.log(jsonData.profile.name);
     //  console.log(jsonData.profile.phonenumber)
         store.dispatch( addLoginUserProfile({ profile: jsonData.profile}) );
         return jsonData;
     } else {
         return 401;
     }
     */
 }

 async function updatePaymentInfo(data) {
    const URL= process.env.REACT_APP_API_URL+'student/updatePayment';
    //  const URL = 'http://dev.rhapp.in/api/runner/login';
    //  const URL = 'http://localhost/api/runner/login';

    const token = localStorage.getItem('token');

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
  
    try {
      const response = await axios.post(URL, data, config );
 //     console.log('👉 Returned data:', response);
    //  alert(JSON.stringify(response));
      return response.data;
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
     
     
 }

 async function deleteClass(data) {
  const URL= process.env.REACT_APP_API_URL+'student/cancelEnrollment';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  try {
    const response = await axios.post(URL, data, config );
 //   console.log('👉 Returned data:', response);
  //  alert(JSON.stringify(response));
    return response.data;
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
   
   
}

async function getEnrollmentsById(classId) {
  const URL= process.env.REACT_APP_API_URL+'student/enrollments?idStClassSchedule='+classId;
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  try {
    const response = await axios.get(URL, config );
 //   console.log('👉 Returned data:', response);
  //  alert(JSON.stringify(response));
    return response.data;
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
   
   
}

async function getAvailableClasses() {
  const URL= process.env.REACT_APP_API_URL+'student/availableClasses';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';
 
  const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  //headers: { 'x-access-token': localStorage.getItem('token') }//Here we are retreiving the access token in our localstorage
  try {
    const response = await axios.get(URL, config );
 //   console.log('👉 Returned data:', response);
  //  alert(JSON.stringify(response));
    return response.data;
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
   
   
}

//          {/*${moment(this.state.listClass.schedule,moment.defaultFormat).format('DD-MMM HH:mm')/*}/*}
/*
<Select name = "paymentStatus"  style={{ fontSize: '12px' }} value={row.paymentStatus}   onChange={event => {row.paymentStatus = event.target.value;  this.updatePaymentStatus(row.idStClass, row.paymentStatus)}}>
                        {/*<MenuItem value=''></MenuItem>}
                        <MenuItem value='NotPaid'>Not Paid</MenuItem>
                        <MenuItem value='Paid'>Paid</MenuItem>
                            </Select> */