import React from 'react';
//import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNewRounded';
//import Dashboard from './Dashboard';
//import * as serviceWorker from './serviceWorker';
//import {logo, phone, password} from './assets/index.js';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';
//import { withRouter } from "react-router";
//import async from async;
const bufferFrom = require('buffer-from');

class HeaderBar extends React.Component {

    handleLogout = e => {

        localStorage.clear();
         
        window.location.href = "/";
    
      }

    render() {
       
        return (
            <header>
               <div className="col-md-12 login-container">
                {/*<div className="aligncenter"> */}
                    <h4>Welcome to Chandra's</h4>
                    <h3>Super 10</h3>
                 {/*</div>*/}
                 </div>
                <div>
               
                {this.props.user? <p className="alignright">Hello {this.props.user}</p> : null}
                <p className="aligncenter"><h4>{this.props.menu}</h4></p>
                {this.props.displayLogout ? 
                    <p className="alignleft">
                    <IconButton aria-label="filter">
                    <PowerSettingsNewIcon onClick={this.handleLogout} />
                  </IconButton>  </p>: null } 
                </div>
            </header>
        );
    }
}
export default HeaderBar;

