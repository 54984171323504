
import React, {Component}  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import LoginScreen from './LoginScreen';
import Homepage from './Homepage';
import store from  './store';
import { Provider } from "react-redux";
import Signup from './Signup';
import Profile from './Profile';
import Dashboard from './Dashboard';

ReactDOM.render(
    <Provider store={store}>
    <Router>
    <Route exact path="/" component={LoginScreen} />
      <Route path="/signup" component={Signup} />
      <Route path="/home" component={Homepage} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/profile" component={Profile} />

   {/*}   <Route path="/listRunners" component={RunnerList} />
      <Route path="/listRegistrations" component={RegistrationsList} />
      <Route path="/runner" component={Runner} />
<Route path="/registration" component={Registration} /> */}
  </Router>,
  </Provider>,
  document.getElementById('root')
); 
