import React from 'react';

import ReactDataGrid from 'react-data-grid';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import * as moment from 'moment';
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Menu from '@material-ui/core/Menu';


import 'bootstrap/dist/css/bootstrap.css';

import {
  Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button
} from 'reactstrap';



const columns = [{ key: 'id', name: 'ID' }, { key: 'title', name: 'Title' }];
const rows = [{ id: 1, title: 'Title 1' },  { id: 2, title: 'Title 2' }];
const rowGetter = rowNumber => rows[rowNumber];

/*const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const useStyles1 = makeStyles({
  table: {
    minWidth: 650,
  },
}); */

const Grid = () => {
  return <ReactDataGrid
    columns={columns}
    rowGetter={rowGetter}
    rowsCount={rows.length}
    minHeight={500} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function MenuAppBar(props) {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [profile, setProfile] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);


  const handleChange = event => {
    setAuth(event.target.checked);
  };

  const handleMenu = event => {
    setAnchorEl1(event.currentTarget);
   //alert('show menu');
  };

  const handleProfile = event => {
     setAnchorEl(event.currentTarget);
   };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleLogout = e => {

    localStorage.clear();
     
    window.location.href = "/";

  }

  const handleUpdateProfile = e => {

    localStorage.clear();
     
   // window.location.href = "/profile";

   

  }

  const processMenu = e => {

    alert(e.target.name);

  }

  const processList = e => {

    alert('process list');

  }

  const processPublish = e => {

    alert('Publish w/o');

  }

  return (
    <div className={classes.root}>
      {/*<FormGroup>
        <FormControlLabel
          control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup>*/}
      <AppBar position="static">
        <Toolbar>
          {props.showMenu==='true' && (
            <div>

            
          <IconButton edge="start" className={classes.menuButton}  onClick={handleMenu} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Menu
                id="menu-appbar1"
                anchorEl={anchorEl1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={open1}
                onClose={handleClose1}
              >
                <MenuItem onClick={processList}>Show List of Students</MenuItem>
                <MenuItem onClick={processPublish}>Publish workout</MenuItem>
          </Menu> </div> )}
          <Typography variant="h6" className={classes.title}>
            Chandra's Super 10
          </Typography>
          {props.loggedIn==='true' && (
            <div>
               <Typography variant="button" display="block" gutterBottom>
        {props.name}
      </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleProfile}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar2"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
               
                <MenuItem onClick={props.updateProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}