import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Dashboard from './Homepage';
//import * as serviceWorker from './serviceWorker';
import {logo, phone, password} from './assets/index.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import { withRouter } from "react-router";
import TextField from '@material-ui/core/TextField';
import HeaderBar from './HeaderBar';

import store from './store';
import { addLoginUserProfile } from './action';
import axios from "axios";
//import async from async;
const bufferFrom = require('buffer-from');


class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {profile: {},
        phonenumber:'',
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessage: ''
    };
        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.props.history.index=0;
    }

    componentDidMount() {

        document.title='Super10';

      //  alert(JSON.stringify(this.state.profile));
      //  alert(JSON.stringify(this.state.stclasses));
    }


    handleChange(event) {
        // console.log(event.target.id);
        const field = event.target.name;
        this.setState({[field]: event.target.value});
    }

    handleSubmit(event) {
   // handleSubmit() {
        let obj = {"phonenumber": this.state.phonenumber, 
                    "name": this.state.name,
                    "email": this.state.email,
                    "password": this.state.password
                    
                 };

        if (this.state.password === this.state.confirmPassword) {
       // console.log(obj);
     //   var buff = new bufferFrom(JSON.stringify(obj)).toString("base64");
        let jsonData = axiosSignup(obj).then(
            externalData => {
           //     console.log('*******async done');
            //    console.log(externalData);
                if (externalData === 401) {
                    this.setState({ errorMessage: 'Invalid Credentials'});
                } else {
                    //    if (externalData.roles.includes('admin')) {
                        this.props.history.push({
                            pathname: '/'
                        //    state: {profile: this.state.profile}
                        })  
                  //  } else {
                    //    this.setState({ errorMessage: 'Not Authorized to perform Admin function'});
                    }
                }
           // }
        );
        } else {
            this.setState({ errorMessage: 'Passwords dont match'});
        }


      
        event.preventDefault();
    }

    async comparePasswords(e) {

        await this.setState({confirmPassword: e.target.value});


        if (this.state.password === this.state.confirmPassword) {
            this.setState({errorMessage : ``});
        } else {
           
            this.setState({errorMessage : `Passwords don't match`});
        }
    }


    render() {
       
        return (
            
           
            <div>
                 <HeaderBar menu='Sign Up' />
                    
                    <div className="col-md-12 login-container">
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div>
                                <TextField
                                    id="standard-number"
                                    label="Phone number"
                                    required
                                    type="number"
                                    value={this.state.phonenumber}
                                    onChange={e => { this.setState({phonenumber: e.target.value})}}
                                    />
                                    <div>
                                    <TextField required id="standard-required" label="Name" 
                                     value={this.state.name}
                                     onChange={e => { this.setState({name: e.target.value})}}
                                    defaultValue="Full Name" />
                                    </div>
                                    <div>
                                    <TextField required id="standard-required" label="Email" 
                                     value={this.state.email}
                                     onChange={e => { this.setState({email: e.target.value})}}
                                    defaultValue="xyz@gmail.com" />
                                    </div>
                                    <div>
                                    <TextField
                                    id="standard-password-input"
                                    label="Password"
                                    value={this.state.password}
                                    onChange={e => { this.setState({password: e.target.value, errorMessage: ''})}}
                                    type="password"
                                    autoComplete="current-password"
                                    />
                                    </div>
                                    <div>
                                    <TextField
                                    id="standard-password-input"
                                    label="Confirm Password"
                                    value={this.state.confirmPassword}
                                    onChange={e => { this.comparePasswords(e)}}
                                    type="password"
                                    autoComplete="current-password"
                                />
                                    </div>
                    </div>
                    <div className='errorMessage'><p>{this.state.errorMessage}</p></div>

                            <input type="submit" className="btn-signin" value="SIGN UP"/>


                        </form>
                    </div>
            </div>
        );
    }
}

export default withRouter(Signup);

async function axiosSignup(data) {
 //   console.log('inside login function');
  //  console.log(creds);
        const URL= process.env.REACT_APP_API_URL+'student/signup';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  try {
    const response = await axios.post(URL, data );
    console.log('👉 Returned data:', response);
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
    return 200;
    /*
    const fetchResult = fetch(URL, {
        method: 'POST',
        body: '',
        headers: {
            Authorization: 'Bearer ' + creds
        },
        credentials: 'include'

    })
    const response = await fetchResult;
  //  console.log(response.status);
    if (response.status === 200) {
        const jsonData = await response.json();
    // console.log(jsonData.profile);
    // console.log(jsonData.profile.name);
    //  console.log(jsonData.profile.phonenumber)
        store.dispatch( addLoginUserProfile({ profile: jsonData.profile}) );
        return jsonData;
    } else {
        return 401;
    }
    */
}


 