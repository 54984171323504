import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './App.css';

import Dashboard from './Homepage';
//import * as serviceWorker from './serviceWorker';
import {logo, phone, password} from './assets/index.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import { withRouter } from "react-router";
import TextField from '@material-ui/core/TextField';
import HeaderBar from './HeaderBar';
import {MenuAppBar} from './MenuAppBar';
import Button from '@material-ui/core/Button';

import store from './store';
import { addLoginUserProfile } from './action';
import axios from "axios";
//import async from async;
const bufferFrom = require('buffer-from');


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {profile: props.location.state.profile,
        stclasses: props.location.state.stclasses,
        phonenumber:'',
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessage: ''
    };
        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.comparePasswords = this.comparePasswords.bind(this);

    }

    componentWillMount() {
        this.props.history.index=0;
    }

    componentDidMount() {

        document.title='Super10';

      //  alert(JSON.stringify(this.state.profile));
      //  alert(JSON.stringify(this.state.stclasses));
    }

    handleChange(event) {
        // console.log(event.target.id);
        const field = event.target.name;
        let updatedProfile = Object.assign({}, this.state.profile);
        updatedProfile[field] = event.target.value;
        this.setState({profile: updatedProfile});
    }

    handleSubmit(event) {
   // handleSubmit() {
    
       
    if (this.state.profile.password === this.state.confirmPassword) {
        this.setState({errorMessage : ``});
        let obj = {"phonenumber": this.state.profile.phonenumber, 
                    "name": this.state.profile.name,
                    "email": this.state.profile.email,
                    "password": this.state.profile.password
                    
                 };
       // console.log(obj);
     //   var buff = new bufferFrom(JSON.stringify(obj)).toString("base64");
        let jsonData = axiosSignup(obj).then(
            externalData => {
           //     console.log('*******async done');
            //    console.log(externalData);
                if (externalData === 401) {
                    this.setState({ errorMessage: 'Invalid Credentials'});
                } else {
                    //    if (externalData.roles.includes('admin')) {
                        alert(JSON.stringify(this.state.stclasses));
                        this.props.history.push({
                            pathname: '/menu',
                        //    state: {profile: this.state.profile}
                            state: {profile: this.state.profile, stclasses: this.state.stclasses}
                        })  
                  //  } else {
                    //    this.setState({ errorMessage: 'Not Authorized to perform Admin function'});
                    }
                }
           // }
        );
    } else {
        this.setState({errorMessage : `Passwords don't match`});
    }

        
      
        event.preventDefault();
    }

    goBack() {
        // console.log(event.target.id);
        this.props.history.push({
            pathname: '/home',
        //    state: {profile: this.state.profile}
            state: {profile: this.state.profile, stclasses: this.state.stclasses}
        })  
    }


    async comparePasswords(e) {

        await this.setState({confirmPassword: e.target.value});

        alert(`comparing ${this.state.password} with ${this.state.confirmPassword}`);

        if (this.state.password === this.state.confirmPassword) {
            this.setState({errorMessage : ``});
        } else {
           
            this.setState({errorMessage : `Passwords don't match`});
        }
    }


    render() {
       
        return (
            
           
            <div>
                
                <MenuAppBar name={this.state.profile.name} showMenu={this.state.profile.phonenumber==='1' ? 'true': 'false'} loggedIn='true'/>

                    <div className="col-md-12 login-container">
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div>
                            <label> <h4>Profile Update</h4> </label> <br></br>
                                    <div>
                                    <TextField disabled id="standard-required" label="Phone number" 
                                     value={this.state.profile.phonenumber}
                                     />
                                      </div>
                                     <div>
                                    <TextField required id="standard-required" label="Name" 
                                    name="name"
                                     value={this.state.profile.name}
                                     onChange={e => this.handleChange(e)}
                                    defaultValue="Full Name" />
                                    </div>
                                    <div>
                                    <TextField required id="standard-required" label="Email" 
                                     name="email"
                                     value={this.state.profile.email}
                                     onChange={e => this.handleChange(e)}
                                    defaultValue="xyz@gmail.com" />
                                    </div>
                                    <div>
                                    <TextField
                                    id="standard-password-input"
                                    label="password"
                                    name="password"
                                    value={this.state.profile.password}
                                    onChange={e => this.handleChange(e)}
                                    type="password"
                                    autoComplete="current-password"
                                    />
                                    </div>
                                    <div>
                                    <TextField
                                    id="standard-password-input"
                                    label="Confirm Password"
                                    value={this.state.profile.password}
                                    onChange={e => { this.comparePasswords(e)}}
                                    type="password"
                                    autoComplete="current-password"
                                />
                                    </div>
                    </div>
                    <div className='errorMessage'><p>{this.state.errorMessage}</p></div>
                    <br></br>
                    <Button variant="contained" color="secondary" onClick={this.goBack}>
  Cancel
</Button>  &nbsp;  &nbsp;
<label>{'  '}</label>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit}>
  Submit
</Button>

                        </form>

                    </div>
            </div>
        );
    }
}

export default withRouter(Profile);

async function axiosSignup(data) {
 //   console.log('inside login function');
  //  console.log(creds);
        const URL= process.env.REACT_APP_API_URL+'student/updateProfile';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  try {
    axios.defaults.withCredentials = true;
    const response = await axios.post(URL, data, {withCredentials: true});
    console.log('👉 Returned data:', response);
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
    return 200;
    /*
    const fetchResult = fetch(URL, {
        method: 'POST',
        body: '',
        headers: {
            Authorization: 'Bearer ' + creds
        },
        credentials: 'include'

    })
    const response = await fetchResult;
  //  console.log(response.status);
    if (response.status === 200) {
        const jsonData = await response.json();
    // console.log(jsonData.profile);
    // console.log(jsonData.profile.name);
    //  console.log(jsonData.profile.phonenumber)
        store.dispatch( addLoginUserProfile({ profile: jsonData.profile}) );
        return jsonData;
    } else {
        return 401;
    }
    */
}


 