// src/js/reducers/index.js
//import { ADD_ARTICLE } from "../constants/action-types";
const initialState = {
  name: '',
  age: 0,
  runnerData: {},
  registrationData: {},
};
function rootReducer(state = initialState, action) {
    if (action.type === 'add_login_user_profile') {
        return Object.assign({}, state, {
            profile: action.payload.profile
        });
        }
  //  return state;
    if (action.type === 'add_runner_data') {
        console.log('adding runner data');
        console.log(action.payload);
        return Object.assign({}, state, {
            runnerData:  action.payload.runnerData
        });
        }

    if (action.type === 'add_registration_data') {
        console.log('adding registration data');
        console.log(action.payload);
        return Object.assign({}, state, {
            registrationData:  action.payload.registrationData
        });
        }

    return state;
}
export default rootReducer;