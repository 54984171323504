import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
//import Dashboard from './dashboard';
//import * as serviceWorker from './serviceWorker';
import {logo, phone, password} from './assets/index.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import { withRouter } from "react-router";
import HeaderBar from './HeaderBar';
import LoginForm from './LoginForm';

//import async from async;
const bufferFrom = require('buffer-from');

class LoginScreen extends React.Component {
    render() {
      return (
        <div className="loginscreen">
            {/*<HeaderBar menu='Sign In'/>*/}
            <LoginForm />
          </div>
          
      );
    }
  }
export default LoginScreen;
