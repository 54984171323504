import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Dashboard from './Homepage';
//import * as serviceWorker from './serviceWorker';
import {logo, phone, password} from './assets/index.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import { withRouter } from "react-router";
import store from './store';
import { addLoginUserProfile } from './action';
import { Link } from 'react-router-dom';
import { MenuAppBar } from './MenuAppBar';
import axios from "axios";
import Loader from './Loader';


//import async from async;
const bufferFrom = require('buffer-from');


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {profile: {},
        stclasses: [],
        errorMessage: '',
        loading: false
    };
        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.props.history.index=0;
    }

    componentDidMount() {
        document.title='Super10';
    }
    
    handleChange(event) {
        // console.log(event.target.id);
        const field = event.target.name;
        this.setState({[field]: event.target.value});
    }

    handleSubmit(event) {
   // handleSubmit() {
        let obj = {"username": this.state.phonenumber, "password": this.state.password };
       // console.log(obj);
       // var buff = new bufferFrom(JSON.stringify(obj)).toString("base64");
       this.setState({loading: true});
        let jsonData = fetchLogin(obj).then(
            externalData => {
                
           //     console.log('*******async done');
            //    console.log(externalData);
                if (externalData === 401) {
                    this.setState({ errorMessage: 'Invalid Credentials'});
                } else {
                        localStorage.setItem('token', externalData.access_token);
                        this.setState({loading: true});
                     //   console.log('setting token -->' + externalData.access_token);
                        if (externalData.user.role === 'admin') {
                            this.props.history.push({
                                pathname: '/dashboard',
                            //    state: {profile: this.state.profile}
                                state: {profile: externalData.user, stclasses: externalData.stclasses}
                            })  
                        } else {
                            this.props.history.push({
                                pathname: '/home',
                            //    state: {profile: this.state.profile}
                                state: {profile: externalData.user, stclasses: externalData.stclasses}
                            })

                        }
                        
                  //  } else {
                        this.setState({ errorMessage: 'Not Authorized to perform Admin function'});
                    }
                }
           // }
        );

      
        event.preventDefault();
    }


    render() {

        if (this.state.loading) return <Loader />;

        return (
            <div>

            
            <MenuAppBar loggedIn='false'/>
            <div className="container login-center-wrap">
                <div className="col-md-12 login-container">
                    <p>Sign in with your Phone Number and Password : </p>
                    <div className="col-md-6 offset-md-3">
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div>
                                <div>
                                    <div className = "input-group-prepend">
                                        <div className="input-group-text"><img src={phone} alt="" /> </div>
                                        
                                            <input type="text" name="phonenumber" className="form-control mobile" id="mobileNumber" aria-describedby="emailHelp" value={this.state.value} onChange={this.handleChange} placeholder="Mobile Number"></input>
                                        </div>
                                    </div>
                                
                            </div>
                            <div>
                                <div>
                                    <div className = "input-group-prepend">
                                    <div className="input-group-text">
                                        <img src={password} alt=""/> </div>
                                        <input type="password" name="password" className="form-control password" id="password" placeholder="********" value={this.state.value} onChange={this.handleChange}></input>
                                    </div>
                                    </div>
                                    
                            </div>
                            <div className='errorMessage'><p>{this.state.errorMessage}</p></div>
                            <button className="btn btn-primary mt-2" type="submit">
            SIGN IN
          </button>
                            {/*<input type="submit" className="btn-signin" value="SIGN IN"/>*/}
                        </form>
                        Not Registered yet?  <Link to="/signup">Sign Up</Link>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default withRouter(LoginForm);

async function fetchLogin(authObject) {
 //   console.log('inside login function');
  //  console.log(creds);
        const URL= process.env.REACT_APP_API_URL+'student/login';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

//  console.log('login with ---> '+JSON.stringify(authObject));

 // const URL= process.env.REACT_APP_API_URL+'student/signup';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  //alert(URL);

  try {
    const response = await axios.post(URL, authObject );

  //  console.log('👉 Returned data:', response);

    return response.data;
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
    return 401;
  }
    
  /*  const fetchResult = fetch(URL, {
        method: 'POST',
        body: authObject
     //   headers: {
     //       Authorization: 'Bearer ' + creds
     //   },
     //   credentials: 'include'

    })
    const response = await fetchResult;
  //  console.log(response.status);
    if (response.status === 200) {
        const jsonData = await response.json();
    // console.log(jsonData.profile);
    // console.log(jsonData.profile.name);
    //  console.log(jsonData.profile.phonenumber)
    //    store.dispatch( addLoginUserProfile({ profile: jsonData.profile}) );
        return jsonData;
    } else {
        return 401;
    }*/
    
}


 