import React, { Component } from 'react';

import ReactDataGrid from 'react-data-grid';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import * as moment from 'moment';
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Menu from '@material-ui/core/Menu';
import {MenuAppBar} from './MenuAppBar';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import PeopleIcon from '@material-ui/icons/People';
import 'bootstrap/dist/css/bootstrap.css';

import {
  Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button
} from 'reactstrap';



const columns = [{ key: 'id', name: 'ID' }, { key: 'title', name: 'Title' }];
const rows = [{ id: 1, title: 'Title 1' },  { id: 2, title: 'Title 2' }];
const rowGetter = rowNumber => rows[rowNumber];

/*const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const useStyles1 = makeStyles({
  table: {
    minWidth: 650,
  },
}); */

const Grid = () => {
  return <ReactDataGrid
    columns={columns}
    rowGetter={rowGetter}
    rowsCount={rows.length}
    minHeight={500} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/*export function MenuAppBar() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = event => {
    setAuth(event.target.checked);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Chandra's Super 10
          </Typography>
          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
} */

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {profile: props.location.state.profile,
      stclasses: props.location.state.stclasses,
    selectedSchedule: '',
    dialogOpen: false,
    deleteClass: '',
    listClass: '',
    classOpen: false,
    classSchedule: '',
    studentlist: [],
    classData: [],
    workout: 'Initial workout value *****',
    selectedDate: '',
    selectedTime: ''
    };
  //  this.setMenu = this.setMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleWorkoutChange = this.handleWorkoutChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleClassClose = this.handleClassClose.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.updateProfile = this.updateProfile.bind(this);
  }

  //classes = useStyles1();

  componentWillMount() {
 
  }

  componentWillUpdate() {
  
  }
  async componentDidMount() {
    document.title='Super10';
    let jsonData = await getClassData().then(
        classData => {
                this.setState({classData: classData})
        });  
  }
    
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

    handleOptionChange = changeEvent => {
      this.setState({
        selectedOption: changeEvent.target.value
      });
    };

    handleScheduleChange = e => {
      this.setState ({
        selectedSchedule: e.target.value
      });
      
    }

    getnextclasses() {

      var date = new Date();
    
      let schedules = [];
     
    
      let daysofweek=[1,2,4];
      date.setDate(date.getDate() - 7);
      for (let k=0; k<4; k++) {
        date.setDate(date.getDate() + 7);
        for (let j=0; j<daysofweek.length; j++) {
         
          var indate = this.getNextDayOfWeek(date,daysofweek[j], 2)
          var inday = moment(indate).format('DD-MM-YYYY');
          let dayOfWeek= indate.getDay();
          let time=this.getTime(dayOfWeek);
          schedules.push(inday +' '+ time);
      
      
        }
    
      }
    
      return schedules;
    
    
    }
    
    getNextDayOfWeek(date, dayOfWeek, weekNumber) {
      // Code to check that date and dayOfWeek are valid left as an exercise ;)
    
      var resultDate = new Date(date.getTime());
    
      resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    
      return resultDate;
    }
    
    getTime(day) {
      switch(day) {
        case 1: return '18:15';
        break;
        default:
        case 2: return '06:00';
        
      }
    }

    renderSchedules(){

      let schedules = this.getnextclasses();
      
      return schedules.map((bg,i) => {
        return (
          <MenuItem value={bg}>{bg}</MenuItem>
        )
      })
    
    }
 
  async handleSubmit(event) {


  
   /* let obj = {"schedule": this.state.selectedSchedule
    };
    // console.log(obj);
    //   var buff = new bufferFrom(JSON.stringify(obj)).toString("base64");
    let jsonData = signupForClass(obj).then(
    externalData => {
    //     console.log('*******async done');
    //    console.log(externalData);
    if (externalData === 401) {
        this.setState({ errorMessage: 'Invalid Credentials'});
    } else {
        //    if (externalData.roles.includes('admin')) {
            this.setState({stclasses: externalData});
            this.props.history.push({
                pathname: '/menu'
            //    state: {profile: this.state.profile}
            })  
      //  } else {
        //    this.setState({ errorMessage: 'Not Authorized to perform Admin function'});
        }
    }
    // }
    ); */

   /* this.setState ({
        dialogOpen: true
      }); */

    let newDate= moment(this.state.selectedDate,moment.defaultFormat).format('YYYY-MM-DD');

    let newTime = moment(this.state.selectedTime,moment.defaultFormat).format('HH:mm');

    let newSchedule = newDate + ' ' + newTime;
    
   // alert(`create schedule for ${newSchedule}`);

    let obj = {"schedule": newSchedule};

    let jsonData = await createSTClass(obj);

    if (jsonData !== 400) {
      
      let classData = await getClassData();
      await this.setState({classData: classData, selectedDate: '', selectedTime: ''});

    }

    event.preventDefault();
  }

  updatePaymentStatus(idStClass, paymentStatus) {

    let updatedStudentlist = this.state.studentlist;

    let objIndex = updatedStudentlist.findIndex((obj => obj.stc_idSTClass == idStClass));

    updatedStudentlist[objIndex].paymentStatus = paymentStatus;

    this.setState({studentlist : updatedStudentlist});

    let obj = {"idStClass": idStClass, "paymentStatus": paymentStatus
    };

    let jsonData = updatePaymentInfo(obj).then(
      externalData => {
      //     console.log('*******async done');
      //    console.log(externalData);
      if (externalData === 400) {
          alert('Oops, something went wrong');
      } else {
      //  alert(`payment status for ${idStClass} is ${paymentStatus}`);
          }
      }
      // }
      );
    //  event.preventDefault();
  
  }

  handleDelete() {

  /*  let updatedSchedules = this.state.stclasses;

    let objIndex = updatedSchedules.findIndex((obj => obj.schedule == schedule));

    let removedSchedules = updatedSchedules.splice(objIndex,1);

    this.setState({stclasses : updatedSchedules});

    this.setState({dialogClose: true}); */

   // alert('can be deleted now')

  }

  handleDialogOpen(e) {

   /*  let updatedSchedules = this.state.stclasses;
 
     let objIndex = updatedSchedules.findIndex((obj => obj.schedule == schedule));
 
     let removedSchedules = updatedSchedules.splice(objIndex,1);
 
     this.setState({stclasses : updatedSchedules}); */
 
     this.setState({dialogOpen: true});
 
 
   
   }

   handleDialogClose(event) {

   

    this.setState({dialogOpen: false});

    if (event.target.name === 'confirm') {


        let updatedSchedules = this.state.classData;

        let objIndex = updatedSchedules.findIndex((obj => obj.schedule == this.state.selectedSchedule));

        updatedSchedules[objIndex].workout = this.state.workout;

        this.setState({classData : updatedSchedules});

        let obj = {"schedule": this.state.selectedSchedule, "workout": this.state.workout};

        let jsonData = updateWorkoutInfo(obj).then(
        externalData => {
        //     console.log('*******async done');
        //    console.log(externalData);
        if (externalData === 400) {
            alert('Oops, something went wrong');
        } else {
           // console.log(`all ok`);
            }
        }
        // }
        );
    
     // alert(` about to save ${this.state.workout}`)

    }

    if (event.target.name === 'cancel') {
      //this.setState({deleteClass: null});
    }


  
  }

  getEnrollments(schedule) {


    let jsonData = getEnrollmentsBySchedule(schedule).then(
      externalData => {
      //     console.log('*******async done');
      //    console.log(externalData);
      if (externalData === 400) {
          alert('Oops, something went wrong');
      } else {
        this.setState({studentlist: externalData});
          }
      }
      // }
      );
  }

  handleClassClose(event) {

    this.setState({classOpen: false});
  
  }

  async handleDateChange(date) {

    await this.setState({selectedDate: date});

   // alert(moment(this.state.selectedDate,moment.defaultFormat).format('DD-MMM'));
  
  }

  async handleTimeChange(time) {

    await this.setState({selectedTime: time});
  //  alert(moment(this.state.selectedTime,moment.defaultFormat).format('HH:mm'));
  }



  updateProfile() {

  //  alert(JSON.stringify(this.state.stclasses));
    this.props.history.push({
      pathname: '/profile',
  //    state: {profile: this.state.profile}
      state: {profile: this.state.profile, stclasses: this.state.stclasses}
  })  
  }

  handleWorkoutChange(event) {
      this.setState({workout: event.target.value})
  }

  //  classes = useStyles();

  render() {

   

    
    return(

      

    <div>
    
    {/*<HeaderBar user={this.state.profile.name} menu='Manage your ST sessions' displayLogout='true'/>*/}
        <MenuAppBar updateProfile={this.updateProfile} name={this.state.profile.name} showMenu={'false'} loggedIn='true'/>

        <div>
            <Container>
   {/*} <form onSubmit={this.handleSubmit}> */}

                <div>
                    <br></br> <br></br>
                    <div>
                        <div className="col text-center justify-content-center align-self-center">
                    
                        </div>
                    </div>
                    <div>
                        <div className="col text-center justify-content-center align-self-center">

                        <label> <h4>Your upcoming ST sessions</h4> </label>
                    <TableContainer component={Paper}>
                        <Table  aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Schedule</TableCell>
                                <TableCell>Enrollment Status</TableCell>
                                <TableCell>Payment Status</TableCell>
                                {/*<TableCell>Cancel</TableCell>*/}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.classData.map(row => (
                                <TableRow key={row.schedule}>
                                <TableCell align="left">
                                    
                                        {moment.utc(row.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}
                                        <IconButton aria-label="list" onClick={event => {this.setState({selectedSchedule: row.schedule, workout: row.workout, dialogOpen: true}); }}>
                                    <FitnessCenterIcon /> </IconButton> 
                                </TableCell>
                                <TableCell align="left">Confirmed: {row.ConfirmedCount ? row.ConfirmedCount : '0'}  Waitlisted: {row.WaitlistedCount ? row.WaitlistedCount : 0 }
                                <IconButton aria-label="list" onClick={event => {this.setState({listClass: row, classOpen: true}); this.getEnrollments(row.schedule)}}>
                                    <PeopleIcon /> </IconButton> 
                                </TableCell>
                                <TableCell align="left">Paid: {row.PaidCount ? row.PaidCount : 0 }  NotPaid: {row.NotPaidCount ? row.NotPaidCount : 0 }  </TableCell>
                                
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br></br><br></br>
          <label>
                <h4> Schedule ST Session </h4>        </label>
                <br></br>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label=""
                    format="dd/MM/yyyy"
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label=""
                    value={this.state.selectedTime}
                    onChange={this.handleTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                        
                </MuiPickersUtilsProvider>

                <div className="col text-center justify-content-center align-self-center">
                  <Button name='confirm' onClick={this.handleSubmit} color="primary" disabled={this.state.selectedDate==='' || this.state.selectedTime===''} autoFocus>
                        Submit
                  </Button>

                </div>
                        <Dialog
                    open={this.state.classOpen}
                    onClose={this.handleClassClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`List of Students for the class ${moment.utc(this.state.listClass.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {/*{moment(this.state.listClass.schedule,moment.defaultFormat).format('DD-MMM HH:mm')}*/}
                            <TableContainer component={Paper}>
                            <Table  aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Enrollment</TableCell>
                                    <TableCell>Payment</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.studentlist.map(row => (
                                    <TableRow key={row.st_name}>
                                    <TableCell align="left">
                                    {row.st_name}
                                    </TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top' }}>{row.stc_enrollmentStatus}
                                    </TableCell>
                                    <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                      
                                    <Select name = "paymentStatus"  style={{ fontSize: '12px' }} value={row.stc_paymentStatus}   onChange={event => {row.stc_paymentStatus = event.target.value;  this.updatePaymentStatus(row.stc_idSTClass, row.stc_paymentStatus)}}>
                        {/*<MenuItem value=''></MenuItem>*/}
                        <MenuItem value='NotPaid'>Not Paid</MenuItem>
                        <MenuItem value='Paid'>Paid</MenuItem>
                            </Select> 
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button name='confirm' onClick={this.handleClassClose} color="primary" autoFocus>
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                        <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                    confirmState={this.showWorkout}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Workout details for ST Session on  ${moment.utc(this.state.selectedSchedule,moment.defaultFormat).format('DD-MMM HH:mm')}`}</DialogTitle>
                    <DialogContent>
                    
                        <DialogContentText id="alert-dialog-description">
                        <TextField
                    id="outlined-multiline-static"
                    name="workout"
                    value={this.state.workout}
                    label="Multiline"
                    multiline
                    rows="4"
                    defaultValue="Default Value"
                    variant="outlined"
                    onChange={this.handleWorkoutChange}
                    />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button name='cancel'onClick={this.handleDialogClose} color="primary">
                        Cancel
                        </Button>
                        <Button name='confirm' onClick={this.handleDialogClose} color="primary" autoFocus>
                        Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </div>
 
    )

  }
}

export default  (Dashboard);

async function signupForClass(data) {
  //   console.log('inside login function');
   //  console.log(creds);
         const URL= process.env.REACT_APP_API_URL+'student/enroll';
   //  const URL = 'http://dev.rhapp.in/api/runner/login';
   //  const URL = 'http://localhost/api/runner/login';

   const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
 
   try {
     const response = await axios.post(URL, data, config);
  //   console.log('👉 Returned data:', response);
   //  alert(JSON.stringify(response));
     return response.data;
   } catch (e) {
     console.log(`😱 Axios request failed: ${e}`);
   }
     
     /*
     const fetchResult = fetch(URL, {
         method: 'POST',
         body: '',
         headers: {
             Authorization: 'Bearer ' + creds
         },
         credentials: 'include'
 
     })
     const response = await fetchResult;
   //  console.log(response.status);
     if (response.status === 200) {
         const jsonData = await response.json();
     // console.log(jsonData.profile);
     // console.log(jsonData.profile.name);
     //  console.log(jsonData.profile.phonenumber)
         store.dispatch( addLoginUserProfile({ profile: jsonData.profile}) );
         return jsonData;
     } else {
         return 401;
     }
     */
 }

 async function updatePaymentInfo(data) {
    const URL= process.env.REACT_APP_API_URL+'student/updatePayment';
    //  const URL = 'http://dev.rhapp.in/api/runner/login';
    //  const URL = 'http://localhost/api/runner/login';

    const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  
    try {
      const response = await axios.post(URL, data, config );
   //   console.log('👉 Returned data:', response);
    //  alert(JSON.stringify(response));
      return response.data;
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
     
     
 }

 async function deleteClass(data) {
  const URL= process.env.REACT_APP_API_URL+'student/cancelEnrollment';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  try {
    const response = await axios.post(URL, data, config );
 //   console.log('👉 Returned data:', response);
  //  alert(JSON.stringify(response));
    return response.data;
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
   
   
}

async function getClassData() {
  const URL= process.env.REACT_APP_API_URL+'student/classData';
  //  const URL = 'http://dev.rhapp.in/api/runner/login';
  //  const URL = 'http://localhost/api/runner/login';

  const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  try {
    const response = await axios.get(URL, config);
  //  console.log('👉 Returned data:', response);
  //  alert(JSON.stringify(response));
    return response.data;
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
   
   
}

async function getEnrollmentsBySchedule(schedule) {
    const URL= process.env.REACT_APP_API_URL+'student/enrollments?schedule='+schedule;
    //  const URL = 'http://dev.rhapp.in/api/runner/login';
    //  const URL = 'http://localhost/api/runner/login';

    const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  
    try {
      const response = await axios.get(URL, config);
   //   console.log('👉 Returned data:', response);
    //  alert(JSON.stringify(response));
      return response.data;
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
     
     
  }

  async function updateWorkoutInfo(data) {
    const URL= process.env.REACT_APP_API_URL+'student/updateWorkout';
    //  const URL = 'http://dev.rhapp.in/api/runner/login';
    //  const URL = 'http://localhost/api/runner/login';
  
    const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
    try {
      const response = await axios.post(URL, data, config );
  //    console.log('👉 Returned data:', response);
    //  alert(JSON.stringify(response));
      return response.data;
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  }

  async function createSTClass(data) {
    const URL= process.env.REACT_APP_API_URL+'student/createSTClass';
    //  const URL = 'http://dev.rhapp.in/api/runner/login';
    //  const URL = 'http://localhost/api/runner/login';

    const token = localStorage.getItem('token');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  
    try {
      const response = await axios.post(URL, data, config );
   //   console.log('👉 Returned data:', response);
    //  alert(JSON.stringify(response));
      return response.data;
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
     
     
 }

